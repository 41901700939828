import get from 'lodash/get';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import CRM_ROUTES from '@domains/CRM/router/CRMRouterMap';
import {
  DATE_FORMAT,
  TIME_ZONE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { INVOICING_PERMISSIONS } from '@domains/Invoicing/const/permissions';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { paymentProviderStatusColors, paymentStatusColors } from './PAYMENT_STATUS';

export function contentFields(isUserVisible = true) {
  const userField = {
    title: 'User / Company',
    displayPriority: 1,
    columnRatio: 2,
    minWidth: 160,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => {
      const to = result.user
        ? { name: CRM_ROUTES.users.detail.index, params: { userUuid: result.user_uuid } }
        : null;
      const href = result.user ? null : companyProfileUrl(result.company_uuid);
      const target = result.user ? null : '_blank';
      const linkPermissions = [result.user ? CRM_PERMISSIONS.viewCrmUsers : CRM_PERMISSIONS.viewCrmCompanies];
      return {
        classLink: 'emobg-link-primary emobg-body-2',
        to,
        href,
        target,
        linkPermissions,
        text: result.user || result.company_commercial_name,
      };
    },
  };
  const fields = [
    {
      title: 'Related invoice',
      displayPriority: 1,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        target: '_blank',
        href: `/invoicing/invoices/${result.invoice_fk}/summary/details`,
        linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        text: result.invoice,
        redirectHandler: true,
      }),
    },
    {
      title: 'City',
      attributeName: 'operator_city_name',
      displayPriority: 1,
      minWidth: 110,
    },
    {
      attributeName: 'updated_at',
      title: 'Date',
      displayPriority: 2,
      columnRatio: 2,
      minWidth: 180,
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended),
    },
    {
      attributeName: 'amount_formatted',
      title: 'Amount',
      displayPriority: 2,
      minWidth: 70,
    },
    {
      attributeName: 'action',
      title: 'Type',
      displayPriority: 2,
      minWidth: 135,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 2,
      minWidth: 140,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: paymentStatusColors[status],
      }),
    },
    {
      attributeName: 'provider_status',
      title: 'Provider status',
      displayPriority: 2,
      minWidth: 150,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: result => ({
        text: sentenceCase(get(result, 'provider_status')),
        color: paymentProviderStatusColors[get(result, 'provider_status')],
      }),
    },
    {
      attributeName: 'payment_method',
      title: 'Payment method',
      displayPriority: 0,
      minWidth: 120,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'provider_reference',
      title: 'Reference',
      type: RECORD_TYPES.link,
      url: value => value.provider_url,
      displayPriority: 0,
    },
  ];

  if (isUserVisible) {
    fields.splice(1, 0, userField);
  }

  return fields;
}

export function facets(operatorTimezone = TIME_ZONE.default) {
  return [
    {
      type: 'rangedatetimepicker',
      props: {
        title: 'Date',
        attributeName: 'updated_at_ts',
        labels: {
          from: 'From',
          to: 'To',
          removeButton: 'Clear dates',
        },
        timezone: operatorTimezone,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'City',
        attributeName: 'operator_city_name',
        limit: 5,
        transformValue: value => value || 'Reindexing',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Status',
        attributeName: 'status',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Provider status',
        attributeName: 'provider_status',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Type',
        attributeName: 'action',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Provider',
        attributeName: 'payment_provider',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        attributeName: 'payment_method',
        title: 'Payment method',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'rangeslider',
      props: {
        title: 'Amount',
        attributeName: 'amount',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Currency',
        attributeName: 'currency',
      },
    },
  ];
}
